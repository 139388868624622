.rcms-btn-brand {
  background-color: $color-brand !important;
  border-color: $color-brand !important;
  color: #fff !important;

  &:hover,
  &:active,
  &:visited,
  &:focus {
    background-color: $color-brand-darken !important;
    border-color: $color-brand-darken !important;
  }
}

.rcms-btn__border-none {
  border: none !important;
}

.rcms-body-container {
  padding-top: calc($height-header + 1rem);

  .rcms-body__content {
    padding-left: 0;
    transition: 0.5s;
  }

  .rcms-body__content-with-sidebar {
    padding-left: $width-sidebar;
    transition: 0.5s;
  }

  .rcms-sidebar-nav {
    position: fixed;
    top: $height-header;
    left: -$width-sidebar * 2;
    width: $width-sidebar;
    height: calc(100vh - $height-header);
    background-color: transparent;
    background-image: linear-gradient(135deg, $color-brand 0%, $color-brand-darken 100%);
    padding: 0.5rem;
    transition: 0.5s;
    z-index: 999;
    border-radius: 0 5px 5px 0;

    .rcms-sidebar-nav-menu {
      a {
        text-decoration: none;
      }

      .rcms-sidebar-nav-menu__item {
        display: grid;
        grid-template-columns: max-content max-content;
        align-items: center;
        column-gap: 0.5rem;
        padding: 0.5rem 0.75rem;
        margin: 0.5rem 0;
        position: relative;
        color: var(--bs-white);
        transition: 0.3s;
        border-radius: 5px;

        &:hover {
          color: $color-brand-darken;
          background-color: var(--bs-white);
          transition: color 0.3s ease;
        }
      }

      .rcms-sidebar-nav-menu__item-active {
        color: $color-brand-darken;
        background-color: var(--bs-white);
      }
    }
  }

  .rcms-sidebar-nav-show {
    left: 0;
  }

  .rcms-tab__content {
    min-height: calc(100vh - ($height-header * 2)) !important;
    width: calc(100vw - 3rem) !important;
    background-color: $color-content-body-light;
  }
}

.rcms-header-nav {
  background-color: var(--bs-body-bg);
  box-shadow: rgba(17, 17, 26, 0.1) 0 1px 0;
  height: $height-header;
}

.rcms-loader-sm {
  width: 24px;
  height: 24px;
  border: 2px solid silver;
  border-bottom-color: $color-brand;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.rcms-min-vh-75 {
  min-height: 75vh !important;
}

.rcms-spinner {
  font-size: 20px;
  width: 2em;
  height: 2em;
  border-radius: 50%;
  box-shadow: inset 0 0 0 0.1em rgba(24, 138, 66, 0.2);
  i {
    position: absolute;
    clip: rect(0, 2em, 2em, 1em);
    width: 2em;
    height: 2em;
    animation: spinner-circle-clipper 1s ease-in-out infinite;
    &:after {
      position: absolute;
      clip: rect(0, 2em, 2em, 1em);
      width: 2em;
      height: 2em;
      content: '';
      animation: spinner-circle 1s ease-in-out infinite;
      border-radius: 50%;
      box-shadow: inset 0 0 0 0.1em $color-brand;
    }
  }
}

.rcms-spinner-sm {
  font-size: 20px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  box-shadow: inset 0 0 0 0.1em rgba(24, 138, 66, 0.2);
  i {
    position: absolute;
    clip: rect(0, 1em, 1em, 0.5em);
    width: 1em;
    height: 1em;
    animation: spinner-circle-clipper 1s ease-in-out infinite;
    &:after {
      position: absolute;
      clip: rect(0, 1em, 1em, 0.5em);
      width: 1em;
      height: 1em;
      content: '';
      animation: spinner-circle 1s ease-in-out infinite;
      border-radius: 50%;
      box-shadow: inset 0 0 0 0.1em $color-brand;
    }
  }
}

.rcms-text-brand {
  color: $color-brand !important;
}
